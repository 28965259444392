import { graphql } from "gatsby"
import Layout from "../components/layout/LayoutNewsAndEvents"
import React from "react"
const NewsIndex = (data) => {
  const news = data.data.allContentfulNewsEvents.nodes
  return <Layout news={news}>&nbsp;</Layout>
}

export default NewsIndex

export const query = graphql`
  query MyQuery {
    allContentfulNewsEvents(sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        slug
        type
        date(formatString: "DD MMMM YYYY")
        thumbnail {
          gatsbyImageData(
            placeholder: TRACED_SVG
            width: 1024
            height: 576
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`
